
.index-page{
  .main-header{
    background: transparent;
    &.sticky-fixed{
      background: #fff;
      .top-nav{
        .nav{
          .open > a, .active a{
            background-color: transparent;
          }
          a{
            color: $fontColor;
            &:active, &:hover{
              color: $focusFontColor;
            }
          }
        }
      }
    }
    .top-nav{
      .logo-white{
        display:block;
      }
      .logo-color{
        display:none;
      }
      .nav{
        > li{
          &.open > a, &.active a, &:active a{
            background-color: transparent;
          }
          > a{
            color: #fff;
            &:hover{
              color: rgba(255,255,255,.7);
            }
          }
        }
      }
    }
  }
  .main-body-heading{
    color: #fff;
    text-align: center;
    //background: url("../img/background-purple.png") no-repeat 50% 100%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 70px;
    .adjustment-space{
      height: 100px;
    }
    .heading-text{
      .title{
        font-size:40px;
        line-height: 2em;
        font-weight: 500;
      }
      .subtext{
        font-size:20px;
      }
      .btn{
        border: 1px solid transparent;
        background-color: #fff;
        color: #3d1a4a;
        background-image: none;
        font-size:14px;
        margin: 20px 0;
        padding: 13px 36px;
        &:hover{
          background-color: rgba(255,255,255,.85);
          color: #3d1a4a;
          border-color: transparent;
          box-shadow: none;
        }
        &.u-btn-shopify{
          border: 1px solid $btnPrimaryColor;
          padding: 13px 36px;
          color: $primaryColor;
          &:hover{
            background-color: $btnPrimaryColor;
            color: #fff;
            .iconfont{
              color: #fff;
            }
          }
        }
      }
    }
  }

  // items
  .app-items-wrapper{
    .panel-heading{
      text-align: center;
      font-weight: 600;
    }
    .app-items{
      a{
        color: $fontColor;
        &:focus{
          color: $focusFontColor;
        }
        text-decoration: none;
      }
      .app-item{
        text-align: center;
        border: 1px solid transparent;
        min-height: 288px;
        margin-bottom: 10px;
        .card-img{
          img{
            margin: auto;
            max-width:150px;
          }
        }
        .card-description{
          overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          max-height: 45px;
          font-size:14px;
          line-height: 1.5em;
        }
        &:hover{
          border-color: #f6f8fe #f6f8fe #a135ee;
          background-color: #f6f8fe;
        }
      }
    }
  }

  // 关键服务
  .feature-point-group{
    .feature-point{
      text-align: center;
      padding: 40px 20px;
      min-height: 168px;
      background-repeat: no-repeat;
      background-size:40%;
      background-position: 66%;
      //&.first-feature{
      //  background-image: url("../img/simple-to-use.png");
      //}
    }
  }

  // 关于我们
  .about-us-wrapper{
    .panel-heading{
      text-align: center;
      font-weight: 600;
    }
    .about-us-text{
      font-size:14px;
      padding:20px 50px;
    }
    .item{
      text-align: center;
      .img-placeholder{
        width:120px;
        height:120px;
        display: inline-block;
        background-size: cover;
        &.shopify{
          background-image: url("../img/shopify.svg");
        }
        &.app{
          background-image: url("../img/app.svg");
        }
        &.customize{
          background-image: url("../img/design.svg");
        }
      }
      .card-heading{
      }
    }
  }
}