
.uninstall-questionnaire{
  .heading{
    padding: 20px 0;
    .face{
      text-align: center;
      svg{
        height:60px;
      }
      padding-bottom:20px;
    }
    .subtext{
    }
  }

  .body{
    .pretty{
      display: block;
      margin-bottom:15px;
    }
  }

  .action-loader{
    display: inline-block;
    vertical-align: bottom;
  }
}