.coupon-apply{
  .coupon-header{
    text-align: center;
  }
  .coupon-body{
    svg{
      vertical-align: middle;
    }
    .coupon-main{
      width: 75%;
      @media screen and (max-width: 679px) {
        width:100%;
      }
      margin: 0 auto;
    }
    .coupon-text{
      vertical-align: middle;
      display: inline-block;
      .btn-wrapper{
        text-align: center;
        margin-top:20px;
      }
    }
  }
}