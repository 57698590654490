
html,body{
  font-family: Nunito Sans,sans-serif;
  font-weight: 400;
  font-size:16px;
  line-height: 1.5em;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  color: #566c8b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility
}

a{
  color:$linkColor;
}
//选中颜色
::selection {
  background: $primaryColor;
  color: #fff;
  text-shadow: none;
}

h2,h3,h4,h5{
  color: #354b68;
}

.main-page{
  position: relative;
  &.page-1{
    padding-top:90px;
  }
}

.main-header{
  background: #fff;
  position: absolute;
  left:0;
  top:0;
  width:100%;
  &.sticky-fixed{
    background: #fff;
    position: fixed;
    top: 0;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.08);
    z-index:9;
    .top-nav{
      .logo-color{
        display:block !important;
      }
      .logo-white{
        display:none !important;
      }
      .nav{
        > .active{
          > a{
            background-color: transparent;
          }
        }
        a{
          color: $fontColor;
          &:active, &:hover{
            color: $focusFontColor;
          }
        }
      }
    }
  }
  // 导航
  .top-nav{
    box-shadow: none;
    background: inherit;
    border: none;
    margin-top: 20px;
    font-size:15px;
    .logo{
      height: 100%;
    }
    .navbar-brand{
      padding: 3px 20px;
    }
    .logo-white{
      display:none;
    }
    .logo-color{
      display:block;
    }
    .nav{
      .open > a{
        background-color: #fff;
      }
      a{
        color: $fontColor;
        &:active, &:hover{
          color: $focusFontColor;
        }
      }
    }
    .navbar-nav{
      .dropdown-menu{
        border: 0;
        border-radius: 2px;
        -webkit-box-shadow: 0 2px 3px rgba(0,0,0,.1);
        box-shadow: 0 2px 3px rgba(0,0,0,.1);
        left: 0;
        top: 50px;
        &:before{
          border: 10px solid transparent;
          border-bottom: 10px solid #fff;
          content: "";
          display: block;
          height: 0;
          left: 30px;
          position: absolute;
          top: -20px;
          width: 0;
        }
      }
    }
  }
}
.main-body{}
.main-footer{
  text-align: center;
  padding:20px;
  .footer-links{
    a{
      color: #6e6f80;
      font-size:12px;
    }
  }
}
// Card 组
.card{
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
  padding: 30px 0 20px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  padding: 20px;
  background-clip: border-box;
  background-color: #fff;
  border: 0 solid rgba(0,0,0,.125);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
  -webkit-transition: -webkit-transform .2s,-webkit-box-shadow .2s;
  transition: -webkit-transform .2s,-webkit-box-shadow .2s;
  -o-transition: box-shadow .2s,-o-transform .2s;
  transition: transform .2s,box-shadow .2s;
  transition: transform .2s,box-shadow .2s,-webkit-transform .2s,-o-transform .2s,-webkit-box-shadow .2s;
  box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
  &:hover, &:focus{
    -webkit-box-shadow: 0 18px 35px rgba(50,50,93,.1),0 8px 15px rgba(0,0,0,.07);
    box-shadow: 0 18px 35px rgba(50,50,93,.1),0 8px 15px rgba(0,0,0,.07);
  }
  &.hover-animate{
    &:hover, &:focus{
      transform: translateY(-2px)
    }
  }
  .card-heading{
    font-size:16px;
    font-weight:600;
    line-height: 2.5em;
  }
  .card-btn{
    margin-top:20px;
  }
}

.btn{
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
// 默认按钮
.u-btn-primary{
  background-color: $btnPrimaryColor;
  border-color: $btnPrimaryColor;
  color: #fff;
  &:hover, &:active{
    background-color: transparent;
    background-image: none;
    border-color: $btnPrimaryColor;
    color: $btnPrimaryColor;
  }
}

.u-btn-primary-o{
  background-color: transparent;
  background-image: none;
  border-color: $btnPrimaryColor;
  color: $btnPrimaryColor;
  &:hover, &:active{
    background-color: $btnPrimaryColor;
    border-color: $btnPrimaryColor;
    color: #fff;
  }
}

.ball-pulse{
  > div{
    background-color: $primaryColor;
  }
}
.action-loader{
  visibility: hidden;
  .ball-pulse{
    display: inline-block;
    vertical-align: middle;
  }
  &.visible{
    visibility: visible;
  }
}


