.privacy-policy{
    .title{
      text-align: center;
    }
    h2{
      font-size:20px;
    }
    dl{
      dt{
        line-height: 2.5em;
      }
    }
    p{
      line-height: 1.6em;
    }
}